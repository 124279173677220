

.landing-container{
    width: 100%;
    margin: auto;
}
.landing-header{
    display: flex;
    align-items:center;
    width: 100%;
    position: relative;
}
.landing-logo{
    width: 15%;
    height: 100px;
   /* border: 1px solid var(--color-white);*/
    background-color: var(--color-logo);  
    display:flex;
    align-items: center;
    justify-content: center;
    
} 
/* Css for humberger menu **/
.hamburger3 {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
   /* position: absolute;
    left: 3rem;
    */
    text-align: center;
}
.hamburger3:hover{
    cursor: pointer;
}
.hamburger3 .line {
    width: 100%;
    height: 3px;
    background-color: #fff;
}

.logo{
    width:80px;
    height:80px;
}
.logo-link{
    text-decoration: none;
    color: var(--color-white);
}
.landing-horizontal-div{
    width: 85%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
   /* border: 1px solid var(--color-white);*/
    /*background-color: var(--color-dark-grey);*/
    background-color: var(--color-input-group-border); 
    color: var(--color-white);
    font-size: var(--font-size-medium);
    padding: 0 20px;
}
.landing-vertical-div{
    min-height: 170vh;
    width: 15%;
    display: flex;
    flex-direction: column;

    /*background-color: var(--color-dark-grey);*/
    background-color: var(--color-input-group-border); 
    color: var(--color-white);
    font-size: var(--font-size-medium);
   /* border: 1px solid var(--color-white); */
   
}
.landing-logo-title{
    font-size: var(--font-size-x-large);
    font-weight: var(--font-weight-bold);
    padding-left:32%;
    text-align: center;
   
}
.welcome-div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}
.welcome-img{
    width: 85%;
    height: 80%;
}
.my-nav-title-hdr{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.my-nav-title-bdy{
    display: flex;
    align-items: center;
}

.icon-left{
   font-size: var(--font-size-xx-large);
   padding-right: 5px;
}

.landing-body{
    display: flex;
    width: 100%;
}
.main-body{
    width: 85%;
    padding: 50px 20px;
}
.small-vertical-menu{
    font-family: var(--font-family-base);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100;
    position: absolute;
    top: 85px;  
    left: 0;
    background-color: var(--color-input-group-border); 
    width: 180px;
    z-index: 100;
    list-style: none;
    padding: 15px 5px;
    font-size: var(--font-size-small);
    color: var(--color-white); 
}
.small-vertical-menu .icon-left{
    font-size: var(--font-size-small); 
}
.small-vertical-menu .menu-item{
    margin: 10px 0;
}
.landing-vertical-menu{
    /*width: 200px;*/
    width: 19%;
    font-family: var(--font-family-base);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 6px;
    margin-top: -5px;
}

.landing-vertical-menu .menu-item{
    list-style: none;
    padding-bottom: 5px;

    border-bottom: 1px solid var(--color-white-light);
    margin: 1rem 0;
    width: 700%; 
}
.sub-menu-item li{
    list-style: none;
    padding: 0;
    margin: 0;
}
.sub-menu-item li{
    padding: 10px;
}
/* .menu-item:hover{
    cursor: pointer;
    position: relative;
    color: var(--color-primary);

} */
.sub-menu-item{
    display: none;  
}

.sub-menu-link{
    color: var(--color-white);
    text-decoration: none;
    padding-left: 5px;
}
.sub-menu-link:hover,.sub-menu-item li:hover{
    cursor: pointer;
    position: relative;
    color: var(--color-primary);
    text-decoration: underline;
}
.my-nav-title-hdr i:hover{
    cursor: pointer;
    color: var(--color-primary);
}

.sub-menu-item.active{
    display: block;
}
.landing-profile{
    display: flex;
}
.profile-img img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid var(--color-white);
}
.profile-img{
    display: flex;
    width: 100px;
    align-items: center;
    justify-content: space-between;
}

.profile-menu{
    position: relative;;
}

.profile-setting{
    display: none;
}
.notifcation-nbr{
   margin-bottom: -5px; 
   margin-left: -5px;
   color: var(--color-white);
   z-index: 10;
   width: 20px;
   height: 20px;
   text-align: center;
   background-color: var(--color-red);
}
.notifcation-nbr:hover{
    cursor: pointer;
    color: var(--color-primary);
}
.prof-notf{
    display: flex;
    flex-direction: column;
}
.prof-down:hover{
    cursor: pointer;
    color: var(--color-primary);
}
.profile-menu li{
    list-style: none;
    padding: 6px;
    margin-bottom: 6px;
}

.profile-setting.active{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-input-group-border);
    width: 220px;
    height: 200px;
    position: absolute;
    top: 40px;
    right: -24px;
    color: var(--color-white);
    padding: 6px;
    z-index:10;
}
.prof-span{
    padding-left: 15px;
}
.visitors-summary{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: var(--font-size-x-large);
    color: var(--color-green);
    padding: 0 15px;
    /* margin-top: -50px; */
}
.visitors-cntr{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    background-color: var(--color-brown-light);
    font-size: var(--font-size-x-large);
    padding: 10px;
    border-radius: 10px;
   
}
.visitors-cntr:hover{
    background-color: var(--color-logo);
    cursor: pointer;
}
.vistors-nbr{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.vistors-nbr i{
    font-size: var(--font-size-xx-large);
    color: var(--color-primary);
}
.visitors-lbl{
    text-align: center;
    color: var(--color-green);
    margin: 0;
    padding: 0;
}

.div-charts{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.LoadMoreStatus{
    margin: 6rem;
    text-align: center;
}
.btn-more-status{
    width: 200px;
    border: 1px solid var(--color-dark-grey);
    border-radius: 6rem;
    font-size: var(--font-size-large);
    color: var(--color-white);
    background-color: var(--color-green);
    padding: 2rem;

}
.btn-more-status:hover{
    cursor: pointer;
    background-color: var(--color-primary);
}

/** for tablet and small labtops ***/
@media (min-width: 768px) and (max-width: 1024px) {
    .landing-logo-title{
        font-size: var(--font-size-x-large);
        font-weight: var(--font-weight-bold);
        padding-left:22%;
        text-align: center;
    }
    .icon-left{
        font-size: var(--font-size-large);
        padding-right: 4px;
     }
     .landing-vertical-div{
        font-size: var(--font-size-small);
     }
     .sub-menu-link{
        padding-left: 2px;
    }
    
    .sub-menu-item{
        margin-left: -1rem;
    }
    .landing-vertical-menu .menu-item{
        width: 850%;
    }
    .div-charts{
        display: flex;
        flex-direction: column;
    }
     
    
}
/************************* media quiers *****************/
/* media quieris for medium mobile size**/
@media (min-width: 468px) and (max-width: 768px) {
    .landing-logo-title{
        font-size: var(--font-size-large);
        font-weight: var(--font-weight-bold);
        padding-left:18%;
        text-align: center;
    }
    .profile-img img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid var(--color-white);
    }
    .profile-menu{
        font-size: var(--font-size-small);
    }
    .hamburger3{
        display: flex;
        left: 1.7rem;
    }
   .landing-logo .logo{
        width: 0;
        height: 0;
    }
    .landing-vertical-div{
        display: none;
    }
    .main-body{
        width: 100%;
        padding: 50px 0;
    }
    .div-charts{
        display: flex;
        flex-direction: column;
    }
       
}
/** for small mobile ***/
@media (min-width: 320px) and (max-width: 468px) {
    .landing-logo-title{
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-bold);
        padding-left:15%;
        text-align: center;
    }
    .profile-img img{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid var(--color-white);
    }
    .profile-menu{
        font-size: var(--font-size-small);
    }
    .hamburger3{
        display: flex;
        left: 1.4rem;
    }
    .logo{
        width: 0;
        height: 0;
    }
    .landing-vertical-div{
        display: none;
    }
    .main-body{
        width: 100%;
        padding: 50px 0;
    }  
    .landing-logo-title{
        visibility: hidden;
    }
    .div-charts{
        display: flex;
        flex-direction: column;
    }
    .visitors-summary{
        font-size: var(--font-size-large);
    }
    .visitors-cntr{
        font-size: var(--font-size-large);
    }
    .vistors-nbr i{
        font-size: var(--font-size-large);
    }
   
}

/** for small mobile ***/
@media (max-width:320px) {
    .landing-logo-title{
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-bold);
        padding-left:15%;
        text-align: center;
    }
    .profile-img img{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid var(--color-white);
    }
    .profile-menu{
        font-size: var(--font-size-small);
    }
    .hamburger3{
        display: flex;
        left: 1.4rem;
    }
    .logo{
        width: 0;
        height: 0;
    }
    .landing-vertical-div{
        display: none;
    }
    .main-body{
        width: 100%;
        padding: 50px 0;
    }  
    .landing-logo-title{
        visibility: hidden;
    }
    .div-charts{
        display: flex;
        flex-direction: column;
    }
    .visitors-summary{
        font-size: var(--font-size-medium);
    }
    .visitors-cntr{
        font-size: var(--font-size-medium);
    }
    .vistors-nbr i{
        font-size: var(--font-size-medium);
    }
    .visitors-summary{
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
     
}