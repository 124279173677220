

.create-HMF, .analysis{
    margin: 20px;
    padding: 20px 0;
    font-size: var(--font-size-medium);
    
}
.create-Hl, .create-MF, .create-An{
    min-height: 20rem;
    width: 100%;
    border-left: 2px solid var(--color-input-group-border);
    border-right: 2px solid var(--color-input-group-border);
    border-bottom: 2px solid var(--color-input-group-border);
    position: relative;
    padding: 1rem;
    margin-bottom: 4rem;
   
}
.create-Hl::before{
    content: '';
    position: absolute;
    top: 0;
    width: 12%;
    height: 2px;
    background-color: var(--color-input-group-border);
}
.create-MF::before{
    content: '';
    position: absolute;
    top: 0;
    width: 19%;
    height: 2px;
    background-color: var(--color-input-group-border);
}
.create-An::before{
    content: '';
    position: absolute;
    top: 0;
    width: 12%;
    height: 2px;
    background-color: var(--color-input-group-border);
}
.create-Hl::after{
    content: '';
    position: absolute;
    top: 0;
    width: 69%;
    height: 2px;
    background-color: var(--color-input-group-border);
}
.create-MF::after{
    content: '';
    position: absolute;
    top: 0;
    width: 67%;
    height: 2px;
    background-color: var(--color-input-group-border);
}
.create-An::after{
    content: '';
    position: absolute;
    top: 0;
    width: 69%;
    height: 2px;
    background-color: var(--color-input-group-border);
}
.create-Hl::before, .create-MF::before, .create-An::before{
    left: 0;
}
.create-Hl::after, .create-MF::after, .create-An::after{
    right: 0;
}
.create-Hl .instruction, .create-MF .instruction, .create-An .instruction{
    position: absolute;
    top: -2.5rem;
    left: 17%;
    transform: translate(-50%);
    background-color: var(--color-white);
    
}

.create-Hl .col-1-1{
    padding-top: 0;
    padding-bottom: 0;
}


.form-input{
   /* padding-bottom: 2rem;*/
    display: flex;
   
}
.form-input-span{
     display: flex;
     width: 100%;
     margin: 0;
     padding: 0;
    
 }
.create-Hl li.col-1-1{
    margin-bottom: -2rem;
}
.form-button{
    position: relative;
    position: absolute;
    bottom: 5px;
    right: 10px;
}

.form-button button{
   margin-right: 6px;
}
 
.form-input label{
    min-width: 150px;
    height: 30px;
    padding-top: 1.7rem;
}
.form-input input, .form-input select {
    min-width: 360px;
    height: 40px;
    border: 1px solid var(--color-input-border);
    outline: none;
}
.input-away{
    width: 400px !important;
}
.form-input input:focus, .form-input select:focus, td input:focus{
    border: 2px solid var(--color-input-border-focus) !important;
}
.form-input .match-search{
    width: 83%;
    height: 30px;
}
.form-button button, .goalScorersDetail button, .PenalityDetail button, .YellowCardDetail button, .RedCardDetail button,
.InjuryDetail button, .SubstitutionDetail button, .FormationDetail button {
    width: 80px;
    height: 40px;
    border-radius: 10%;
    border-color: var(--color-input-border);
    
}
.form-button button:hover, .goalScorersDetail button:hover, .PenalityDetail button:hover, .YellowCardDetail button:hover, .RedCardDetail button:hover,
.InjuryDetail button:hover, .SubstitutionDetail button:hover, .FormationDetail button:hover{
   background-color: var(--color-primary);
   border-color: var(--color-input-border-focus);
   cursor: pointer;
}
.create-MF .form-title-home {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    text-align: center;
}
.create-MF .form-title-away {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    margin-left: 4rem;
}
.goalScorers, .penality, .Fbcard, .injury, .substitution, .formations {
    width: 40px;
    height: 36px;
    border-radius: 10%;
    border-color: var(--color-input-border);
    background-color: var(--color-input-border);
    text-align: center;
    /*margin-top: 12px;*/
}
.goalScorers:hover, .penality:hover, .Fbcard:hover, .injury:hover, .substitution:hover, .formations:hover{
    cursor: pointer;
    background-color: var(--color-primary);
    border-color: var(--color-input-border-focus);
}
.goalScorersDetail, .PenalityDetail, .RedCardDetail, .YellowCardDetail, .InjuryDetail, .SubstitutionDetail, .FormationDetail{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 2rem 3rem 2rem 2rem;
    padding: 1rem 0;
}

/* formations */
.formation-analysis{
    padding: 2px;
}
.players-analysis{
    overflow-y: scroll;
    height: 1100px;
}
.players-draggable{
    position: relative;
    margin-top: -2.5rem;
    margin-left: -2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.player-thirtNo{
    margin-top: -1.2rem;
    /*margin-left: 2.3rem;*/
    color: var(--color-white);
    font-size: var(--font-size-small);
}
.Player-name{
    margin-top: -1.5rem;
    margin-left: 1rem;
    color: var(--color-white);
    font-size: var(--font-size-medium);
    text-wrap: nowraprf4vqa1z;
}
.linueup-analysis{
    color: var(--color-white-light2);
    position: relative; 
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
    margin-top: -2rem;    
}
.linueup-analysis .goal-keepper-analysis.drop,.linueup-analysis .drop, .substitues-analysis .drop {
    width: 50%;
    height: 10%;
    border: 2px dotted var(--color-white-light3); 
    display: block;
}
.linueup-analysis .drop.dragging, .linueup-analysis .goal-keepper-analysis.drop.dragging, .substitues-analysis .drop.dragging {
    width: 50%;
    height: 10%;
    border: 2px dotted var(--color-primary);
}
.best-11-analysis{
    border: 2px solid var(--color-white);
    width: 100%;
    height: 740px;
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem;
    position: absolute;
    left: 1px;
    right: 1px;
}
.penality-aria-analysis{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 15%;
    width: 40%;
    border-bottom:2px solid var(--color-white);
    border-left: 2px solid var(--color-white);
    border-right: 2px solid var(--color-white);
    position: absolute;
    top: 0;
    left: 30%;
}
.goal-post-analysis{
    height: 20px;
    width: 60%;
    border-bottom:2px solid var(--color-white);
    border-left: 2px solid var(--color-white);
    border-right: 2px solid var(--color-white);  
    position: absolute;
    top: 0;
}
.goal-keepper-analysis{
    position: absolute;
    margin-top: -2rem;
    height: 30% !important;
    width: 18% !important;
}
.penality-dot-analysis{
    width: 5px;
    height: 5px;
    border-radius: 100%;
    border: 4px solid var(--color-white);
    position: absolute;
    top:  25px;
}
.best-10-analysis{
    position: absolute;
    top: 5rem;
    left: 1px;
    right: 1px;
   
}
.players-sameline-analysis{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.penality-header-analysis{
    width: 17%;
    height: 6%;
    border: 2px solid var(--color-white);
    border-color: transparent var(--color-white) var(--color-white);
    border-radius: 0 0 200px 200px; 
    position: absolute;
    left: 42%;
    top: 15%;
    
}
.half-space-analysis{
    border-bottom: 2px solid var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    top: 50%;
}
.ball-circle-analysis{
    border: 2px solid var(--color-white);
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    position: absolute;
    
}

.penality-aria-up-analysis{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 15%;
    width: 40%;
    border-top:2px solid var(--color-white);
    border-left: 2px solid var(--color-white);
    border-right: 2px solid var(--color-white);
    position: absolute;
    bottom: 0;
    left: 30%;
}
.goal-post-up-analysis{
    height: 20px;
    width: 60%;
    border-top:2px solid var(--color-white);
    border-left: 2px solid var(--color-white);
    border-right: 2px solid var(--color-white);  
    position: absolute;
    bottom: 0;
}
.penality-dot-up-analysis{
    width: 5px;
    height: 5px;
    border-radius: 100%;
    border: 4px solid var(--color-white);
    position: absolute;
    bottom: 25px;
}
.penality-header-up-analysis{
    width: 17%;
    height: 6%;
    border: 2px solid var(--color-white);
    border-color:  var(--color-white) var(--color-white) transparent;
    border-radius: 200px 200px 0 0; 
    position: absolute;
    left: 42%;
    bottom: 15%;
}
.substitues-analysis{
    padding:3rem 0 1rem 2rem;
    position: absolute;
    margin-top: 740px;
    width: 100%;
}
.sub-title-analysis{
    font-family: var(--font-family-title);
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
    text-align: left;
    color: var(--color-white);
}
.substitues-analysis li{
    height: 1rem;
}

.linueup-analysis .same-line{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    width: 100%;
    
}
.linueup-analysis .player-one, .linueup-analysis .player-two, .linueup-analysis .player-three, 
.linueup-analysis .player-four, .linueup-analysis .player-five, .linueup-analysis .player-six, .linueup-analysis .player-seven{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 20%;
    
}
.linueup-analysis .player-down,.linueup-analysis .player-along,.linueup-analysis .player-up{
    padding: 1rem;
}

.dynamic-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -2rem;
    
}
.dynamic-para{
    margin-top: -1rem;
}
.resized-image{
    width: 60px !important;
    height: 60px !important;
}
.resized-image-sub{
    width: 10% !important;
    height: 10% !important;
}
.resized-font{
   font-size: var(--font-size-small);
}
.resized-font-sub{
    font-size: var(--font-size-medium);
    margin: 0;
    padding: 0.5rem;
 }
.resized-div{
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
    justify-content: flex-start;
}
.substitues-body{
    padding: 2rem !important;
}
.substitues-body.dragging{
    width: 100%;
    height: 300px;
    border: 1px dotted var(--color-primary);
}
.form-success-hdr{
    font-size: var(--font-size-medium);
    color: var(--color-green);
    width: 100%;
    text-align: center;
    padding: 0 10px;
    
}
.field-input{
    display: flex;
    flex-direction: column;
    padding: 8px;
    width:100%
   
}
.match-actions{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin:  0;
    padding: 0;
}
.match-actions button{
    width: 30px;
    height: 30px;
    margin-right: 6px;
}
.match-actions button:hover{
    cursor: pointer;
    background-color: var(--color-primary);
}
.match-filter-hdr{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: space-between; */
}
.filter-select, .filter-input{
    width: 45%;
    padding: 0;
    margin-top: 0;
    margin-right: 6px;
    margin-bottom: 6px;
   
    outline: none;
    
}
.filter-select:focus, .filter-input:focus{
    border: 2px solid var(--color-input-border-focus) !important;
}
.filter-input{
    height: 40px;
    border: 1px solid var(--color-input-border);
}
.match-filter-btn{
    width: 37px;
    height: 37px;
    margin-bottom: 7px;
    border: 1px solid var(--color-input-border);
}
.match-filter-btn:hover{
    cursor: pointer;
    background-color: var(--color-primary);
}
.delete-all{
    width: 70px;
    height: 40px;
}
.delete-all:hover{
    background-color: var(--color-primary);
    cursor: pointer;
}

/** for tablet and small labtops ***/
@media (min-width: 768px) and (max-width: 1250px) {
    .create-Hl::before{
        width: 15%;
    }
    .create-MF::before{
        width: 20%;
    }
    .create-An::before{
        width: 15%;
    }
    .create-Hl::after{
        width: 46%;
    }
    .create-MF::after{
        width: 42%;
    }
    .create-An::after{
        width: 46%;
    }
    .create-Hl .instruction, .create-MF .instruction, .create-An .instruction{
        left: 30%;
    }
}
.match-fact p:first-child{
    text-align: justify;
}


/** for medium mobile ***/
@media (min-width: 468px) and (max-width:768px) {
    
    .create-Hl::before{
        width: 20%;
    }
    .create-MF::before{
        width: 20%;
    }
    .create-An::before{
        width: 20%;
    }
    .create-Hl::after{
        width: 28%;
    }
    .create-MF::after{
        width: 22%;
    }
    .create-An::after{
        width: 28%;
    }
    .create-Hl .instruction, .create-MF .instruction, .create-An .instruction{
        left: 40%;
        text-wrap: nowrap;
    }
    .create-Hl .col-1-2:last-child{
        margin-top: 0;
        padding-top: 0; 
    }
    .create-Hl .urls{
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .create-MF .col-1-2:last-child{
        margin-top: 0;
        padding-top: 0; 
    }
    .create-MF .form-title-away{
        text-align: center;
    }

    .match-filter-hdr{
        justify-content: flex-end
    }
    .filter-select, .filter-input{
        width: 100%;
    }
    .match-filter-btn{
        margin-right: 7px;
    }
}

/** for medium mobile ***/
@media (max-width: 468px) {
    
    .create-HMF, .analysis{
        font-size: var(--font-size-small);
    }
    .create-Hl::before{
        width: 15%;
    }
    .create-MF::before{
        width: 20%;
    }
    .create-An::before{
        width: 15%;
    }
    .create-Hl::after{
        width: 18%;
    }
    .create-MF::after{
        width: 12%;
    }
    .create-An::after{
        width: 21%;
    }
    .create-Hl .instruction, .create-MF .instruction, .create-An .instruction{
        left: 45%;
        text-wrap: nowrap;
        top: -1.7rem;
    }
    .create-Hl .col-1-2:last-child{
        margin-top: 0;
        padding-top: 0; 
    }
    .create-Hl .urls{
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .create-MF .col-1-2:last-child{
        margin-top: 0;
        padding-top: 0; 
    }
    .create-MF .form-title-away{
        text-align: center;
    }
    .match-filter-hdr{
        justify-content: flex-end
    }
    .filter-select, .filter-input{
        width: 100%;
    }
    .match-filter-btn{
        margin-right: 7px;
    }
}