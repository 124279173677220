
.analysis{
    /* width: 100%; */
    height: 100%; 
}
.analysis-controls{
    display: flex;
    align-items: center;
    justify-content: center;
}
.analysis-controls button{
    width: 150px;
    height: 60px;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    padding: 2px;
}
.analysis-controls button:hover, .add-section:hover{
    background-color: var(--color-primary);
    cursor: pointer;
}

/* create a tooltip text & make it invisible */
.add-section::after{
    content: attr(data-tooltip);
    white-space: nowrap;
    position: absolute;
    bottom:  calc(100% + 10px);
    border-radius: 2px;
    left: 50%;
    background: var(--color-dark-grey);
    color: var(--color-white);
    border-radius: 3px;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    padding: 4px;
}
/* create arrow pointing downward above button and make it invisible */
.add-section::before {
    content: '';
    position: absolute;
    bottom: calc(100% - 10px); /* Position the arrow right below the button */
    left: 50%;
    transform: translateX(-50%);
    border-width: 1rem;
    border-style: solid;
    border-color: var(--color-dark-grey) transparent transparent transparent;
    opacity: 0;
  }
  
  /* on hover show the tooltip with arrow pointing downward*/
.add-section:hover::after,
.add-section:hover::before {
  opacity: 1;
}

.analysis-controls .icon{
    margin-right: 5px;
    font-size: var(--font-size-large);
}
.analysis-controls .text{
    white-space: nowrap;
    font-size: var(--font-size-large);
}
.analysis-area{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}
.section-controls{
    display: none;
}
.section-controls.active{
    display: block;
}
.add-section{
    width: 50px;
    height: 50px;
    margin-top: 3rem;
    border-radius: 50%;
    font-size: var(--font-size-large);
    position: relative;
}
.section-body{
    display: none;
}
.section-close{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: var(--font-size-large);
    color: var(--color-red);
}
.section-close:hover{
    cursor: pointer;
}
.section-body.active{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    border: 1px dotted var(--color-section);
    margin: 1rem;
    padding: 1rem;
}
.analysis-button{
    position: relative;
    margin: 2rem;
    padding: 1rem;
    height: 6rem;
}
.analysis-save.active{
    display: block;
}
.analysis-save{
    width: 80px;
    height: 40px;
    border-radius: 10%;
    border-color: var(--color-input-border);
    position: absolute;
    right: 100px;
    bottom: -20px;
    display: none;
}
.analysis-cancel{
    width: 80px;
    height: 40px;
    border-radius: 10%;
    border-color: var(--color-input-border);
    position: absolute;
    right: 10px;
    bottom: -20px;
}

.analysis-button button:hover{
    background-color: var(--color-primary);
    border-color: var(--color-input-border-focus);
    cursor: pointer;
}

.section-intro{
    font-size: var(--font-size-large);
    padding: 4px;
    text-transform: uppercase;
}
.sections{
    display: flex;
    align-items: center;
    justify-content: center;
}
.sections .one-column p:hover, .sections .two-equal-column:hover .each-section,.sections .three-equal-column:hover .each-section,.sections .four-equal-column:hover .each-section,
.sections .two-left-small-column:hover .each-section, .sections .two-right-small-column:hover .each-section, .sections .three-left-small-column:hover .each-section,
.sections .three-right-small-column:hover .each-section, .sections .three-left-right-small-column:hover .each-section{
    background-color: var(--color-primary);
    cursor: pointer;
}

.sections .one-column p{
    width: 60px;
    height: 40px;
    background-color: var(--color-section);
}
.section-separator{
    margin: 0 1px;
}
.sections .two-equal-column, .sections .one-column, .sections .three-equal-column, .sections .four-equal-column, .sections .two-left-small-column,
.sections .two-right-small-column, .sections .three-left-small-column, .sections .three-right-small-column,  .sections .three-left-right-small-column{
    display: flex;
    margin-right: 2rem;
}
.sections .two-equal-column p,.sections .three-equal-column p,.sections .four-equal-column p{
    width: 30px;
    height: 40px;
    background-color: var(--color-section);
}
.sections .two-left-small-column > p:nth-of-type(1), .sections .two-right-small-column >p:nth-of-type(2){
    width: 15px;
    height: 40px;
    background-color: var(--color-section);
}
.sections .two-left-small-column > p:nth-of-type(2), .sections .two-right-small-column >p:nth-of-type(1){
    width: 45px;
    height: 40px;
    background-color: var(--color-section);
}
.sections .three-left-small-column > p:nth-of-type(1), .sections .three-left-small-column > p:nth-of-type(2), .sections .three-right-small-column > p:nth-of-type(2), .sections .three-right-small-column > p:nth-of-type(3){
    width: 15px;
    height: 40px;
    background-color: var(--color-section);
}
.sections .three-left-small-column > p:nth-of-type(3), .sections .three-right-small-column > p:nth-of-type(1), .sections .three-left-right-small-column > p:nth-of-type(2){
    width: 45px;
    height: 40px;
    background-color: var(--color-section);
}
.sections .three-left-right-small-column > p:nth-of-type(1), .sections .three-left-right-small-column > p:nth-of-type(3){
    width: 15px;
    height: 40px;
    background-color: var(--color-section);
}

/** handle dynamically generated sections **/
.all-sections{
    margin: 1rem 0;
    width: 100%;
}
.new-section{
    margin: .2rem 0;
    width: 100%;
    position: relative;
}
.new-section li{
    border: 1px dotted var(--color-section);
    margin: 2px;
}

.new-section .col-1-1.dragging{
    border-color: var(--color-primary);
    
}

.new-section .bx-plus{
    font-size: var(--font-size-large);
}
.new-para{
    font-size: var(--font-size-medium);
    text-align: left;
}
.new-section .plus-icon{
    text-align: center;
    display: block;
}
.new-section .plus-icon.dragging{
    display: none;
}
.delete-section{
    position: absolute;
    top: 0;
    right: 0;
    font-size: var(--font-size-large);
    color: var(--color-red);
}
.delete-section:hover{
    cursor: pointer;
}
.para-controls{
    display: none;
}
.para-controls.show{
    display: flex;
    align-items: center;
    justify-content: center;
}
.all-span{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-dark-grey);
    color: var(--color-white);
    font-size: var(--font-size-medium);
    width: 120px;
    margin: 1rem;
}
.para-controls span{
    padding: 4px;
}
.para-controls span:hover{
    cursor: pointer;
}
.make-bold{
    font-weight: var(--font-weight-bold);
}

