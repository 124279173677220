

.login-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: var(--font-size-medium);
    width: 50%;
    margin: auto;
}
.login{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 700px;
    background: url('../../Assets/images/stadium.jpg') center/cover no-repeat;
    height: 540px;
    margin: 2rem 0;
    position: relative;
    flex-direction: column;
    color: var(--color-white);
}
.login-hdr{
    text-align: center;
    padding-bottom: 25px;
}
.login-title{
    margin-top: -6px;
}
.forgot-password{
    width: 50%;
    margin-right: 32px;
    font-size: var(--font-size-small);
    text-align: right;
    
}
.forgot-link{
    color: rgb(62, 193, 229);
    text-decoration: underline;
}
.forgot-link:hover{
    cursor: pointer;
    color: var(--color-primary);
}
.password-reset-div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}
.btn-password-reset{
    width: 200px !important;
    border-radius: 5px !important;
}
.log-input{
    /* background-color: var(--color-white); */
    margin: 6px;
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
}
.recaptcha-div{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 15px auto;
    transform: scale(1.06);
    transform-origin: center;
    
}
.recaptcha-div > div{
    width: auto !important;
}

.log-input-btn{
    margin: 6px;
    color: var(--color-black);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}
.log-input-btn button{
    margin-right: 20px;
    
}

.login input {
    min-width: 280px;
    height: 40px;
    border: 1px solid var(--color-input-border);
    outline: none;
    border-radius: 0;
}
.login-div{
    display: flex;
    align-items: center;
}
.login-icon{
    background-color: var(--color-white);
    width: 40px;
    height: 40px;
    padding: 6px;
    border: 1px solid var(--color-input-border);
    border-radius: 0;
    text-align: center;
}
.login input:focus{
    border: 2px solid var(--color-input-border-focus) !important;
}
.login button{
    padding: 4px;
    width: 80px;
    height: 40px;
    border-radius: 10%;
    border-color: var(--color-input-border);
}
.login button:hover{
    cursor: pointer;
    background-color: var(--color-primary);
}
.form-error-login{
    font-size: var(--font-size-medium);
    color: var(--color-red);
    width: 100%;
    text-align: left;
    padding: 2px 20px;
    display: block;
}
.form-error-login-hdr{
    font-size: var(--font-size-medium);
    color: var(--color-red);
    width: 100%;
    text-align: center;
    padding: 0 10px;
    
}
.success-msg2{
   margin: 0;
   padding: 0;
}
.login-link{
    font-size: var(--font-size-medium);
    color: var(--color-white);
    text-decoration: underline;
}
.login-link:hover{
    cursor: pointer;
    color: var(--color-primary);
}
.profile-module{
    position: absolute;
    top: 220px;
    padding: 0 4px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-medium);
    
}
.profile-title{
    width: 50%;
    height: var(--search-header);
    text-align: center;
    font-size: var(--font-size-large);
    background: var(--color-input-group-border);
    padding: 12px;
    color: var(--color-white);
}
.profile-footer{
    width: 82%;
    height: var(--search-footer-height);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-large);
    
}
.profile-footer button {
    margin-right: 4px;
    width: 90px;
    height: 40px;
    border-radius: 10px;
    border-color: var(--color-input-border);
}
.profile-footer button:hover{
    background-color: var(--color-primary);
    border-color: var(--color-input-border-focus);
    cursor: pointer;
}
.pwrdChange-footer button {
    margin-right: 4px;
    width: 140px;
    height: 40px;
    border-radius: 10px;
    border-color: var(--color-input-border);
}
.pwrdChange-footer button:hover{
    background-color: var(--color-primary);
    border-color: var(--color-input-border-focus);
    cursor: pointer;
}
.field-img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.field-img img{
    width: 120px;
    height: 120px;
}
.field-img img:hover{
    cursor: pointer;
}
.prof-input{
    display: none;
}


/** media queries ***/
/** for tablet and small labtops ***/
@media (min-width: 468px) and (max-width:  768px) {
    .profile-module{
        width: 100%;
        padding:  0 40px;
    }
    .login-container{
        width: 80%;
    }
    .login input {
        min-width: 250px;
    }
    .recaptcha-div{
        margin: 10px auto;
        transform: scale(0.96);
        transform-origin: center;
        
    }
    .profile-module .col-1-2{
        margin: 0;
        padding: 0;
    }
}
/** for medium mobile ***/
@media (max-width: 468px) {
    .profile-module{
        width: 100%;
        padding:  0 40px;
        font-size: var(--font-size-small);
    }
    .profile-title{
        font-size: var(--font-size-medium);
        width: 80%;
    }
    .profile-module .col-1-2{
        margin: 0;
        padding: 0;
    }
    .profile-footer button {
        width: 60px;
    }  
}

