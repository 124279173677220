

.page-content{
   /* width: 1250px;*/
     width: 100%;
   /* height: 700px;*/
    margin: auto;
    display: flex;
    flex-direction: column;
   /* border: 1px solid var(--color-input-group-border); */
    padding: 5px;

    
}
.page-body{
    height: var(--grid-height);
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}
.search-module, .add-module{
    border: 1px solid var(--color-input-group-border);
    margin: 6px;
    padding: 0 4px;
    width: 38%;
    height: var(--grid-height);
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-medium);
    position: relative;  
}
.search-title, .add-title{
    width: 100%;
    height: var(--search-header);
    position: absolute;
    top: 0;
    text-align: center;
    font-size: var(--font-size-large);
    background: var(--color-input-group-border);
    margin: 0 -4px;
    padding: 12px;
    color: var(--color-white);
    
}
.search-body, .add-body{
    position: absolute;
    top: 60px;
   /* width: 100%;*/
    right:8px;
    left: 10px;
    height: var(--search-body-height);
    overflow-x: scroll;
    overflow-y: scroll;
}

.search-row-hdr, .add-row-hdr{
    display: flex;
    flex-direction: column;
    width: 380px;
    
}
.add-row-generate{
    min-width: 82%;
    display: flex;
    flex-wrap: nowrap;
}


.search-row, .add-row, .add-row-last, .add-row-span{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    
}
.search-row label, .add-row label, .add-row-span  label{
    text-align: left;
    min-width: 120px;
    height: 30px;
    padding-top: 2px;
    margin-right: 6px;
    
}
.add-row-last label{
    text-align: left;
    min-width: 120px;
    height: 30px;
    margin-right: 6px;
    margin-top: -30px;
    
}
.search-row input, .add-row input{
    min-width: 100%;
    height: 35px;
    border: 1px solid var(--color-input-border);
    outline: none;
    margin-bottom: 15px
}
.add-row textarea{
    min-width: 100%;
    height: 13rem;
    border: 1px solid var(--color-input-border);
    outline: none;
    margin-bottom: 15px
}
.add-row-span input{
    min-width: 90%;
    height: 35px;
    border: 1px solid var(--color-input-border);
    outline: none;
    margin-bottom: 15px
}
.add-row-last input{
    min-width: 700px;
    height: 30px;
    border: 1px solid var(--color-input-border);
    outline: none;
    margin-top: -30px
}
.search-select{
    min-width: 100%;
    margin-bottom: 15px;
    margin-top: -4px; 
}
.search-select__control{
    height: 35px;
    min-height: 35px;
    width: 100%;
    
}
.search-select__value-container{
    padding: 8px;
}
.search-select__indicators{
    height: 35px;
    min-height: 35px;
}
.file-upload-input{
    position: relative;
    display: inline-block;
}
.file-upload-input input[type="file"]{
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

}
.file-upload-input::before{
    content: 'Choose file';
    display: inline-block;
    border: 1px solid var(--color-input-group-border);
    border-radius: 5px;
    background-color: var(--color-button);
    cursor: pointer;
    padding: 5px;
    text-align: center;
}
.file-upload-input::before:hover{
    background-color: var(--color-primary);
}

/* .masterId, .detailId, .playerId{
    min-width: 180px !important;
} */
.file-name{
    width: 150px !important;
    border: 1px solid var(--color-input-border);
    border-radius: 5px;
    outline: none;
    padding: 6px 16px;
    margin-right: 3px;
    display: inline-block;
    /*overflow: hidden;*/
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 35px;
}
.file-upload-div{
    display: flex;
}

.search-row input:focus, .add-row input:focus, .add-row textarea:focus, .filter-hdr input:focus, .add-row-last input:focus, .add-row-span input:focus{
    border: 2px solid var(--color-input-border-focus) !important;
}
.add-row.validation input, .add-row.validation input:focus, .add-row-last.validation input:focus, .add-row-span.validation input:focus{
    border: 2px solid var(--color-red) !important;
}
.search-footer, .add-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: var(--search-footer-height);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-large);
    background: var(--color-input-group-border);
    margin: 0 -4px;
    
}
.search-footer button, .add-footer button {
    margin-right: 4px;
    width: 25%;
    height: 55%;
    border-radius: 10%;
    border-color: var(--color-input-border);
}
.search-footer button:hover, .add-footer button:hover, .btn-generate:hover, .popup-yes:hover, .popup-no:hover{
    background-color: var(--color-primary);
    border-color: var(--color-input-border-focus);
    cursor: pointer;
}
.btn-generate, .btn-generate-edit{
    height: 34px;
    width: 70px;
    padding: 4px;
    margin-left: 1px;
    border-color: var(--color-input-border);
}
.btn-generate-edit:hover{
    cursor: not-allowed;
}


.actions{
    height: var(--action-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-large);
}
.retrive-data, .update-data{
    display: flex;
   
    
}
/** change the header color of data grid view **/
.ag-theme-quartz .ag-header-cell{
    background-color: var(--color-input-group-border);
    color: var(--color-white);
}

/** change the height of the column separator **/
.ag-theme-quartz .ag-header-cell::after,
.ag-theme-quartz .ag-cell::after{
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    height: 50%;
    background-color: var(--color-white);
    margin: auto 0;
}

/** change the border of the last cell to none **/
.ag-theme-quartz .ag-header-cell:last-child::after,
.ag-theme-quartz .ag-cell:last-child::after{
    display: none;
}

/**change the border color of data grid view **/
.ag-theme-quartz .ag-root-wrapper{
    border: 1px solid var(--color-input-group-border) !important;
}
/** do not change the header color on hover **/
.ag-theme-quartz .ag-header-cell:hover{
    background-color: var(--color-input-group-border) !important;
}
/** do not change the header label color on hover **/
.ag-theme-quartz .ag-header-cell:hover .ag-header-cell-label{
    color: var(--color-white) !important;
}

.retrive-data button, .update-data button{
    margin-right: 20px;
    border: none;
    background-color: var(--color-white);
    width: 40px;
    height: 40px;
}


.retrive-data i, .update-data i{
    font-size: var(--font-size-large);
}

.retrive-data button:hover, .update-data button:hover{
    border: 1px solid black;
    color: var(--color-primary);
    cursor: pointer;
}
.form-error{
    font-size: var(--font-size-small);
    color: var(--color-red);
    width: 100%;
    text-align: right;
    padding: 5px 25px 0 0;
    display: block;
}
.form-error-hdr{
    font-size: var(--font-size-small);
    color: var(--color-red);
    width: 100%;
    text-align: center;
    padding: 0 10px;
    
}
.unauthorized{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: var(--font-size-medium);
}

/* change the selected rows of an ag data grid */
.selected-row{
    background-color: var(--color-light-blue);
}
.grid-div-img{
    width: 40px;
    height: 40px;
    
}
.grid-icon{
    font-size: 40px; 
}
.grid-icon-detail{
    font-size: 240px;
    text-align: center;
}
.grid-div-txt{
    width: 100%;
    height: 40px;
    
}
.grid-img{
    width: 100%;
    height: 100%;
    background-color: var(--color-input-border);
    border-radius: 50%;
}
.grid-img:hover, .grid-icon:hover{
    cursor: pointer;
}
.grid-txt{
    margin: 2px;
    text-decoration: underline;
    color: var(--color-blue);
}
.grid-txt:hover{
    text-decoration: underline;
    color: var(--color-primary);
    cursor: pointer;
}

.player-detail-div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 12px;
   
}

.player-detail-div p{
    padding: 0;
    margin: 0;
    text-align: right;
}
.player-detail-label{
    text-align: right;
    font-weight: var(--font-weight-bold);
}

.player-detail-img{
    width: 100%;
    height: 100%;
}
.player-detail-value-left{
    width: 100%;
    margin: 0 10px;
    font-weight: var(--font-weight-bold);
}
.ag-row-even{
    background-color: var(--color-light-grey2) !important;
}
.ag-row-odd{
    background-color: var(--color-white) !important;
}
.ag-grid-container{
    
     height: 100%; 
     width: 100%;
     margin: 6px 0;

     display: flex;
     flex-direction: column;
    
}

.ag-theme-quartz{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.ag-root-wrapper{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.ag-root-wrapper-body{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.ag-body-viewport-wrapper{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
    

.ag-paging-panel{
    flex-shrink: 0;
}
.ag-theme-quartz > div[style*="height: 100%"]{
    display: flex !important;
    flex-direction: column;
}
.img-show{
    width: 60px;
    height: 60px;
}

.filter-hdr{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.filter-hdr input{
    min-width: 400px;
    height: 40px;
    border: 1px solid var(--color-input-border);
    outline: none;
    margin-right: 4px;
}
.filter-btn{
    background: none;
    width: 40px;
    height: 40px;
}
.filter-btn:hover{
    background-color: var(--color-primary);
    cursor: pointer;
}
.filter-body{
    display: flex;
    flex-direction: column;
    margin-top: -20px;
    padding: 10px;
}
.ag-grid-cont{
    width: 100%;
    height: 250px;
}



/** for tablet and small labtops ***/
@media (min-width: 768px) and (max-width: 1147px) {
    .search-module, .add-module{
        min-width: 45%;
    }
    .actions{
        font-size: var(--font-size-medium);
    }
    .search-row label, .add-row label, .add-row-span label{
        text-align: left;
    }
}

/** for medium mobile ***/
@media (min-width: 468px) and (max-width: 768px) {
    .search-module, .add-module{
        min-width: 50%;
    }
    .actions p{
        display: none;
    } 
    .search-row input, .add-row input{
        width: 100%;
    }
    .add-row-span input{
        width: 90%;
    }
    .search-row label, .add-row label, .add-row-span label{
        text-align: left;
    }
    .search-select{
        width: 100%; 
    }
    .search-select__control{
        width: 100%;
    }
}

/** for medium mobile ***/
@media (max-width: 468px) {
    .search-module, .add-module{
        width: 100%;
        min-height: var(--grid-height);
        order: 1;
    }
    
    .actions p{
        display: none;
    }
    .page-body{
        flex-direction: column;
    }
    .ag-grid-container{
        order: 3;
    }
    
    .ag-paging-row-summary-panel{
        display: none;
    }

    .search-row input, .add-row input{
        width: 100%;
    }
    .add-row-span input{
        width: 90%;
    }
    .search-row label, .add-row label, .add-row-span label{
        text-align: left;
    }
    .search-select{
        width: 100%; 
    }
    .search-select__control{
        width: 100%;
    }
    
    
}
 
 


 
