/** declare variable to be accessed throughout the css**/
:root{
    /** variables for setting colors **/
    --color-primary: #d73953;
    --color-primary-light:  #dd687b;
    --color-primary-light2: rgb(219, 139, 152);
    --color-primary-light3: rgb(236, 167, 179);
    --color-black: #090909;
    --color-white: #ffffff;
    --color-light-grey: #f3f3f3;
    --color-light-grey2: #f2f2f2;
    --color-dark-grey: #353535;
    --color-light-blue: lightblue;
    --text-color: var(--color-black);
    --color-blue-light: #c4e9f5;
    --color-white-light: #cccccc;
    --color-white-light2: #f3eeee;
    --color-white-light3: #637177;
    --color-brown-light: #b9c2c64a;
    --color-green:#249660;
    --color-grass:#16b833;
    --color-blue-light2:#0e6082;
    --color-spin-light:  rgba(25, 99, 163, 0.662);
    --color-spin: rgb(0, 136, 255);
    --color-input-border-focus:#2a95e7;
    --color-yellow: rgb(201, 201, 13);
    --color-red: red;
    --color-button: #f0f0f0;
    --color-section: #bececb;
    --color-input-group-border: #597170;
    --color-logo: #2a4742;
    --color-input-border: #b8c0c0;
    --container-width: 1300px;

    /** variables for setting font families **/
    --font-family-base: 'Open Sans','Helvetica Neue', Helvetica, Arial, sans-serif;
    --font-family-title: 'Raleway','Helvetica Neue', Helvetica, Arial, sans-serif;

    /** variables for setting font size **/
    --font-size-small: 1.2rem;
    --font-size-medium: 1.6rem;
    --font-size-large: 1.8rem;
    --font-size-x-large: 2.3rem;
    --font-size-xx-large: 3.3rem;
    --font-size-xxx-large: 4.8rem;

    /** variables for setting font weight **/
    --font-weight-regular: 400;
    --font-weight-bold: 700;

    /** variables for line height **/
    --line-height-small: 1.2;
    --line-height-base: 1.5;
    --line-height-big: 1.8;

    /** variables for section variable **/
    --section-header-align: center;
    --section-tagline-transform: uppercase;
    --section-title-margin:0;
    --section-title-color: var(--color-black);
    --section-tagline-margin:0;

    /** variables for section padding **/
    --section-padding: 5rem 0;
    --section-header-padding: 0 0 3rem;
    --section-body-padding: 0 0 3rem;
    --section-footer-padding: 3rem 0 0;
    --section-footer-align: center;
    --footer-padding: 5rem 0 1rem;

    /** variable for nav **/
    --nav-item-font-family:var(--font-family-title);
    --nav-item-font-weight:var(--font-weight-bold);
    --nav-item-font-size:var(--font-size-medium);
    --nav-item-letter-spacing: 0.04rem;
    --nav-item-dispaly: inline-block;
    --nav-item-margin: 0 0 3rem 0;
    --nav-item-link-hover: var(--color-white); 


    /** variable for buttons **/
    --button-display:inline-block;
    --button-padding: 1.5rem 3rem;
    --button-border: 0.2rem solid  var(--color-primary);
    --button-color: var(--color-black);
    --button-text-decoration: none;
    --button-font-size: var(--font-size-large);
    --button-hover-color:var(--color-white);
    --button-hover-text-decoration: none;
    --button-hover-background: var(--color-primary);


    /** variable for header **/
    --header-padding: 4rem 0;
    --header-logo-position: relative;
    --header-logo-link-display:inline-block;
    --header-logo-link-position: absolute;
    --header-logo-link-top: -1rem;
    --header-logo-link-left: 0;


    /** variable for transition animation **/
    --transition-duration: .3s;
    --transtion-cubic-bezier:cubic-bezier(0.17, 0.67, 0, 1.01);  

    /** height of grid **/
    --current-page-height: 50px;
    --action-height: 60px ;
    --space-height: 22px;
    --grid-height: calc(100vh - calc(var(--current-page-height) + var(--action-height) + var(--space-height)));
    --search-footer-height: 60px;
    --search-header: 50px;
    --search-space: 12px;
    --video-player-height: calc(100vh / 1.5 );
    --video-player-body-height: calc(100vh / 1.54 ) ;
    --search-body-height: calc(var(--grid-height) - calc(var(--search-footer-height) + var(--search-header) + var(--search-space)) )
   
   
}

/** universal box sizing rule for creating layouts **/
*, *:before, *:after{
    box-sizing: border-box;
}

html{
    /* smoth transition while scrolling */
    scroll-behavior: smooth;
    font-size: 75%;
}

/** divider **/
.horizontal-divider{
    border-bottom: 1px solid var(--color-white-light);
    margin: 1rem 0;
    width: 100%;
}
.horizontal-divider-black{
    border-bottom: 1px solid var(--color-dark-grey);
    margin: 1rem 0;
    width: 100%;
}
.horizontal-divider-brown-light{
    border-bottom: 1px solid var(--color-brown-light);
    width: 80%;
    margin: 1rem auto;
}
.horizontal-divider-negativemargin{
    border-bottom: 1px solid var(--color-white);
    width: 100%; 
    margin-top: -37rem;
}
.horizontal-divider-withoutmargin{
    border-bottom: 1px solid var(--color-white-light);
    width: 100%;
}
.vertical-space{
    padding-bottom: 1rem;
}
.vertical-space-margin{
    margin-bottom: 2rem;
}
.vertical-space-2{
    padding-bottom: 2rem;
}
.vertical-space-both{
    margin: 4rem 0;
}
.divider-title{
    text-align: center;
    font-family: var(--font-family-title);
    font-size: var(--font-size-large);
    color: var(--color-input-border);
    margin: 0;
    padding: 0;
    font-weight: var(--font-weight-bold);
}

/** container **/
.my-container{
    /* width: var(--container-width); */
    width: 90%;
    margin: auto;

}
/* row related **/
ul.row{
    margin: 0;
    padding: 0;
    list-style: none;
}
.col-1-1{
    width: 100%;
}
.col-1-3{
    width: 32.33%;
}
.col-1-2{
    width: 49%;
}
.col-1-4{
   width: 24%;
}
.col-3-4{
    width: 65.66%; 
 }
.col-2-4{
    width: 36%;
 }
.col-1-5{
    width: 19.33%;
}
.col-2-5{
    width: 75.33%;
}
.col-3-5{
    width: 53%;
}
.col-4-5{
    width: 63%;
}
.row::after{
    content: "";
    display: table;
    clear: both;
}
[class^="col-"]{
    float: left;
    padding: 2rem;
}
/** style link **/
.link{
    text-decoration: none;
    font-weight: var(--font-weight-bold);
}
.link:hover{
    cursor: pointer;
    color: var(--color-primary);
}
.success-msg{
    width: 100%;
    height: 60px;
    background-color: var(--color-grass);
    color: var(--color-white);
    position: relative;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 8px 0;
    
}

.btn-close{
    position: absolute;
    right: 1px;
    top: 1px;
    background-color: var(--color-primary);
}
.btn-close:hover{
    cursor: pointer;
}
.current-page{
    width: 100%;
    margin: auto;
    height: var(--current-page-height);
    background: var(--color-input-group-border);
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--font-size-large);
    border-radius: 5px;
}
.current-page p{
    padding: 4px;
}
.current-page .link{
    text-decoration: underline;
    color: var(--color-white);
}
.current-page .link:hover{
    color: var(--color-primary);
}
/** tooltip div which contains the buttons **/
.tooltip-div{
    position: relative;
}
/* create a tooltip text & make it invisible */
.btn::after {
    content: attr(data-tooltip);
    white-space: nowrap;
    position: absolute;
    top:   calc(80% + 10px);
    border-radius: 2px;
    left: 50%;
    background: var(--color-dark-grey);
    color: var(--color-white);
    border-radius: 3px;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    padding: 5px 15px;
}
/* create arrow pointing downward above button and make it invisible */
.btn::before {
    content: '';
    position: absolute;
    top: calc(80% - 10px); /* Position the arrow right below the button */
    left: 50%;
    transform: translateX(-50%);
    border-width: 1rem;
    border-style: solid;
    border-color:  transparent  transparent  var(--color-dark-grey) transparent;
    opacity: 0;
  }
  /* on hover show the tooltip with arrow pointing downward*/
 .btn:hover::after,
 .btn:hover::before {
   opacity: 1;
   z-index: 103;
 }

 /** style the customer loading overlay **/
 .custom-loading-overlay{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
 }
 .spinner{
    border: 4px solid var(--color-spin-light);
    border-left-color: var(--color-spin);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
 }
 @keyframes spin{
    to {transform: rotate((360deg));}
 }
 
 .dropdown-content{
    margin-top: -15px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 80px;
    width: 80px;
    z-index: 100;
 }
 .dropdown-content button{
    height: 25px !important;
    width: 80%;
    padding: 2px 5px !important;
    margin-top: -10px;
    background: none;
 }

 /** media query for common css **/
 /* media quieris for mobile size**/
 @media (max-width: 768px) {
    ul.row, .row{
        flex-direction: column;
        margin: 0;
      }
  
      [class*='col-']{
        margin: 0 0 3rem 0;
      }
  
      .col-1-3, .col-1-2{
        width: 100%;
      }
      .col-1-4{
        width: 50%;
    }
      .col-1-2{
        text-align: left;
      }
      .current-page{
        font-size: var(--font-size-medium);
    }
    .divider-title{
        font-size: var(--font-size-medium);
        
    }
 }

 /** for tablet and small labtops ***/
@media (min-width: 768px) and (max-width: 1024px) {
    .col-1-3{
        width: 49%;
    }
    [class*='col-']{
        margin: 0 0 3rem 0;
      }
      .current-page{
        font-size: var(--font-size-medium);
    }
    .col-1-4{
        width: 50%;
    }
}

 