
.Comment{
    width: 100%;
    padding: 2rem 0;
    font-size: var(--font-size-medium);
}
.Comment-title{
    font-size: var(--font-size-large);
    background-color: var(--color-dark-grey);
    font-weight: var(--font-weight-bold);
    color: var(--color-white);
    width: 40%;
    text-align: center;
    padding: 1rem;
}
.Comments-given{
    width: 100%;
    padding: 2rem;
}
.comments-each{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 2rem;
}
.comment-hdr{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}
.comment-by{
    display: flex;
}
.comment-by img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid var(--color-dark-grey)
}
.comment-by span{
    padding: 2rem;
}
.comment-like-dislike{
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.comment-like-dislike button{
    margin-top: -1.5rem;
}
.comment-like button{
    background-color: var(--color-green);
}
.comment-like button:hover, .comment-dislike button:hover{
    cursor: pointer;
}
.comment-dislike button{
    background-color: var(--color-red);
}
.comment-like, .comment-dislike{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
    
}
.comment-body{
    padding: 2px 0 2px 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.comment-reply{
    padding-left: 4rem;
    margin-top: -2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}
.comment-reply-content{
    font-style: italic;
}
.comment-reply-by{
    margin-top: 2rem;
    font-size: var(--font-size-small);
}
.comment-footer{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
}
.btn-reply{
    width: 80px;
    height: 40px;
    text-align: center;
    border-radius: 5px;
    margin-top: -1rem;
    background-color: var(--color-blue-light2);
    color: var(--color-white);
}
.btn-reply:hover, .Comments-to-be-given button:hover, .comment-reply-input button:hover{
    background-color: var(--color-primary);
    cursor: pointer;
}
.comment-date{
    margin-top: -1rem;
    font-size: var(--font-size-small);
}
.Comments-to-be-given{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Comments-to-be-given textarea{
    width: 100%;
    height: 13rem;
}
.Comments-to-be-given input, .comment-reply-input input{
    width: 50%;
    height: 40px;
}
.Comments-to-be-given button, .comment-reply-input button{
    height: 40px;
    width: 15%;
    background-color: var(--color-blue-light2);
    color: var(--color-white);
}
.Comments-to-be-given textarea, .Comments-to-be-given input, .Comments-to-be-given button{
    margin:  1rem 0;
    border-radius: 5px;
}
.comment-reply-input{
    padding-left: 12rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.comment-reply-input textarea{
    width: 100%;
}

.reply-submit-cancel{
    display: flex;
    width: 100%;
}
.reply-submit-cancel button{
    margin-right: 2rem;
}

/** media query for comments css **/
 /* media quieris for mobile size**/
 @media (max-width: 768px) {
    .Comment, .Comment-title{
        font-size: var(--font-size-small);
        width: 100%;
    }
    .Comments-to-be-given input, .comment-reply-input input{
        width: 100%;
    }
    .Comments-to-be-given button{
        width: 40%;
        margin: 2px auto;
    }
    .Comments-to-be-given textarea,  .Comments-to-be-given input, .Comments-to-be-given button{
        margin:   0;
    }
    .comment-body{
        padding: 2px 0 2px 60px;
    }
    .comment-by img{
        width: 40px;
        height: 40px;
    }
    .comment-by span{
        padding: 1rem;
    }
    .comment-date{
        margin-top: 0;
    }
    .btn-reply{
        width: 70px;
        height: 40px;
        margin-top: 0;
        font-size: var(--font-size-small);
       
    }
    .comment-reply-input{
        padding-left: 4rem;
        width: 100%;
    }
    .comment-reply-input button{
        width: 40%;
        margin: 2px auto;
    }
    .comment-reply{
        padding-left: 2rem;
        margin-top: -2rem;
        flex-direction: column;
        justify-content: flex-start;
    }
   
    .comment-reply-by{
        margin: -1rem  0 1rem 4rem;
        text-align: right;
        font-size: var(--font-size-small);
    }
  
 }



